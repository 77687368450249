<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The pressure in a vessel that contained pure oxygen gas dropped at a rate of
        <number-value :value="urms1" unit="\text{atm/min}" />
        as the oxygen leaked through a small hole into a vacuum. When the same vessel was filled
        with a fictional gas, the pressure dropped at a rate of
        <number-value :value="urms2" unit="\text{atm/min.}" />
        What is the molecular weight of the fictional gas?
      </p>

      <calculation-input
        v-model="inputs.molWeight"
        prepend-text="$\text{Molecular Weight:}$"
        append-text="$\text{amu}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';

export default {
  name: 'Question266',
  components: {
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        molWeight: null,
      },
    };
  },
  computed: {
    urms1() {
      return this.taskState.getValueBySymbol('urms1').content;
    },
    urms2() {
      return this.taskState.getValueBySymbol('urms2').content;
    },
  },
};
</script>
